.logo{
    width:65px;
    aspect-ratio: 3.8/2;
    object-fit: contain;
    margin-left: 10px;
   
}

.brandlogo{
    width:150px;
    aspect-ratio: 100/89;
    object-fit: contain;

}

h1,h2 {
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .heads2{
   
  text-align: center;
  }

  .smalltext{
font-size: 14px;
text-align: center;
margin: auto;
  }