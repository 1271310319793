.footer-1{   
    width:"80%";
    margin:  20PX;
    display: flex;
  justify-content: space-evenly;
  
    flex-wrap: wrap;
}
.footer{
    color: #ffffff;
   padding: 25px;
    background-color: #043e28c4;
    
}

.foothead{
color:white;
}

.footer-2{
  height:30px;
  background-color: #163026dc;
}

.footmenue{ 
  color:white;
  text-decoration:wavy;

}

.footmenue:hover{
  color:rgb(188, 187, 187);
  transition:0.3s;
}