


.cardImg{
    aspect-ratio: 1;
    object-fit: contain;
    width: 60%;
    margin:10px auto 10px auto;
}

#courses{
    text-align: center;
}