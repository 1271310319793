#cdpage{
text-align: center;
}

.para2{
    font-size: 18px ;
  margin-top:25px;
    text-align: justify;
    line-Height:1.5;
    font-weight:400;
    color: rgb(255, 255, 255);
}
.para3{
    font-size: 18px ;
  margin-top:10px;
    text-align: justify;
   
    font-weight:400;
    color: rgb(255, 255, 255);
}
.para4{
    font-size: 18px ;
  margin-top:10px;
    text-align: justify;
   
    font-weight:400;
    color: rgb(112, 112, 112);
}
.para5{
    font-size: 18px ;
  
    text-align: justify;
   
    font-weight:400;
    color: rgb(112, 112, 112);
}

.crds2:hover{
   
    box-shadow: rgba(191, 191, 196, 0.567) 0px 5px 0px -20px, rgba(181, 179, 179, 0.616) 0px 30px 60px -30px, rgba(128, 131, 134, 0.898) 0px -2px 6px 0px inset;
    transition:0.3s;
}

.bgimg{
  
    background-size:cover;
   background-repeat: no-repeat;  
    
    
}


.card2Img{
aspect-ratio: 1;
object-fit: contain;
width: 30%;
margin:10px auto 10px auto;
}

.h2{
    display: flex;
    justify-content: left;
    color: #ffffff;
}
.h2b{
    display: flex;
    justify-content: left;
    color: #225094;
}


.fees, h4{
    color: #225094;
}


