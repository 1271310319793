#home{
text-align: center;
}

.carosoulImg{
    
    width: 70%;
}

h2, h5{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.para{
    font-size: 18px ;
  margin-top:25px;
    text-align: justify;
    line-Height:2;
    font-weight:400;
    color: rgb(93, 93, 93);
}
.desktopsiteimg{
  margin: auto;
width: 90%;
}

.intro{
    /* font-weight: 800; */
    color: red;
}

.crds:hover{
   scale:1.05;
        background-color: #0255d1e2;    
        transition: 0.5s; 
           color: white;     
}


.cardImg{
 
}

@media only screen and (max-width: 768px) {
    /* CSS rules specific to mobile devices */
    .formCard{
        width:95%;
          
          background-color:white;
         
         border-radius: 20px;
      }
      .forMobrow{
        display:block;
        
        

      } }