.cardImg2{
    aspect-ratio: 1;
    object-fit: contain;
    width: 20%;
    height: 100px;
    margin:10px auto 10px auto;
}
.formobcontact1{
    width:80%;
    margin:25px auto;
    text-align: center;
}
 
.formobcontact3{
width: '70%';
margin: 25px auto;
text-align: center;
}
#contactform{
    width:70%;
    margin:25px auto;
    border:1px solid rgb(206, 206, 206);
    border-radius:10px;
     box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
     padding:20px;
    }

.crds3:hover{   
    scale: 1.1;
    transition: 0.5s;
    
}

#gmap_canvas{
    border:1px solid rgb(190, 190, 190);
    border-radius: 5px;

}


@media only screen and (max-width: 768px) {
    /* CSS rules specific to mobile devices */
    
    .formobcontact1, .formobcontact2,.formobcontact3{
        width:100%;
    }

    #contactform{
        width:100%;
      
        }
      }