.formCard{

    width:50%;
    margin:25px auto;
    background-color:white;
    padding:20px;
   border-radius: 20px;
}




 

  @media only screen and (max-width: 768px) {
    /* CSS rules specific to mobile devices */
    .formCard{
        width:95%;
          
          background-color:white;
         
         border-radius: 20px;
      }
      .forMobrow{
        display:block;
        
        

      } }